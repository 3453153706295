<template>
  <v-container :style="{ padding: '0', marginTop: '12px' }" v-if="dataLoaded">
    <v-row>
      <v-col cols="12" class="tw-py-0">
        <div class="tw-pt-5 tw-px-12">
          <h2
            class="tw-text-left tw-font-figtree tw-text-lg tw-font-semibold tw-leading-30 tw-text-dark-green"
          >
            Let’s Invite the Crew
          </h2>

          <p
            class="info-blurb tw-text-left tw-font-figtree tw-font-normal tw-text-light-grey tw-mt-4 tw-mb-8"
            v-if="isOwner || isOrganizer"
          >
            Create or edit your invitation and send a link to your crew so they
            can join the trip.
          </p>
          <p
            v-else
            class="info-blurb tw-text-left tw-font-figtree tw-font-normal tw-text-light-grey tw-mt-4 tw-mb-8"
          >
            Bringing a friend? Share the invite link below and text or email it
            to add them to the trip.
          </p>
          <v-btn
            rounded
            color="secondary"
            large
            block
            class="tw-tracking-normal tw-mb-12"
            @click="
              $router.push({
                name: 'CreateInvitationForm'
              })
            "
            v-if="!trip.trip_invitation && (isOwner || isOrganizer)"
            >Create Invitation</v-btn
          >
          <button
            class="tw-bg-white tw-rounded-md tw-shadow-custom tw-p-3 tw-font-semibold tw-flex tw-flex-row tw-items-center tw-mb-14"
            @click="copyLink"
            v-if="trip && trip.trip_invitation"
          >
            <span class="tw-mr-6" v-html="copyBtnLabel"></span>
            <v-icon size="16">mdi-content-copy</v-icon>
          </button>
          <template
            v-if="trip && trip.trip_invitation && (isOwner || isOrganizer)"
          >
            <v-btn
              class="tw-tracking-normal tw-mt-8"
              color="secondary"
              block
              x-large
              depressed
              rounded
              target="_blank"
              :to="{
                name: 'Invitation',
                params: { uuid: trip.trip_invitation.uuid }
              }"
              >Preview Invite</v-btn
            >
            <v-btn
              class="tw-tracking-normal tw-mt-3"
              block
              color="secondary"
              depressed
              x-large
              rounded
              outlined
              :to="{
                name: 'CreateInvitationForm',
                params: {
                  id: trip.id,
                  inviteId: trip.trip_invitation.uuid
                }
              }"
              >Edit Invite</v-btn
            >
          </template>

          <v-btn
            v-if="trip.trip_invitation && !isOwner && !isOrganizer"
            class="tw-tracking-normal tw-mt-8"
            color="secondary"
            block
            x-large
            depressed
            rounded
            target="_blank"
            :to="{
              name: 'Invitation',
              params: { uuid: trip.trip_invitation?.uuid }
            }"
            >See Invitation</v-btn
          >
        </div>
        <v-divider
          class="tw-mt-14 tw-border-separator-grey tw-border-4 tw-border-solid"
        ></v-divider>
      </v-col>
      <v-col cols="12" class="tw-py-0">
        <div class="tw-pt-10 tw-px-12">
          <p
            class="tw-text-left tw-font-figtree tw-text-dark-green tw-font-semibold tw-mb-2"
          >
            Response Deadline
          </p>
          <p
            class="info-blurb tw-text-left tw-font-figtree tw-font-normal tw-text-light-grey"
            v-if="isOwner || isOrganizer"
          >
            Your crew can join your trip and change their RSVP before the
            deadline. Create invite to set a response deadline for replies.
          </p>
          <p
            class="info-blurb tw-text-left tw-font-figtree tw-font-normal tw-text-light-grey"
            v-else
          >
            Update your RSVP before the deadline.
          </p>
          <v-expansion-panels
            v-if="(isOwner || isOrganizer) && trip && trip.trip_invitation"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div
                  class="j-panel-header tw-flex tw-flex-row tw-items-center tw-justify-between"
                >
                  <v-icon>mdi-calendar-blank</v-icon>
                  <div
                    class="tw-text-dark-green tw-text-base tw-font-semibold tw-mx-auto"
                  >
                    <span v-if="trip && tripRSVPDeadline">{{
                      getFormattedDate(tripRSVPDeadline)
                    }}</span>
                    <span v-else>Select a date</span>
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-date-picker
                  v-model="tripRSVPDeadline"
                  class="tw-py-4"
                  no-title
                  color="secondary"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <p
            class="tw-text-left tw-text-lg tw-font-figtree tw-font-light tw-text-dark-green tw-mt-8"
            v-else-if="trip && trip.trip_invitation"
          >
            {{
              trip && tripRSVPDeadline
                ? getFormattedDate(tripRSVPDeadline)
                : "TBD"
            }}
          </p>
        </div>

        <v-divider
          class="tw-mt-12 tw-border-separator-grey tw-border-4 tw-border-solid"
        ></v-divider>

        <div class="tw-pt-10 tw-px-12">
          <p
            class="tw-text-left tw-font-figtree tw-text-dark-green tw-font-semibold tw-mb-2 tw-pt-0"
          >
            Track RSVPs & Manage Permissions
          </p>
          <p
            class="info-blurb tw-text-left tw-font-figtree tw-font-normal tw-text-light-grey"
          >
            Stay updated as crew members submit or update responses. Be sure
            your notifications are on.
          </p>
          <p
            class="tw-text-left tw-text-sm tw-cursor-pointer"
            @click="showExplanation = true"
          >
            How does the Organizer role work?
          </p>
          <ListItem type="Going" :list="going" @edit="setActiveUser"></ListItem>
          <ListItem type="Maybe" :list="maybe" @edit="setActiveUser"></ListItem>
          <ListItem
            type="Declined"
            :list="declined"
            @edit="setActiveUser"
          ></ListItem>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showExplanation"
      max-width="400"
      content-class="tw-rounded-3xl"
    >
      <div class="tw-text-left tw-py-10 tw-px-16 tw-bg-white tw-relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          class="tw-absolute tw-right-6 tw-top-6 tw-cursor-pointer"
          @click="showExplanation = false"
        >
          <path
            d="M23.2405 11.941L22.0026 10.7031L17.0949 15.6109L12.1871 10.7031L10.9492 11.941L15.857 16.8488L10.9492 21.7565L12.1871 22.9944L17.0949 18.0867L22.0026 22.9944L23.2405 21.7565L18.3328 16.8488L23.2405 11.941Z"
            fill="#203848"
          />
          <circle
            cx="17.0957"
            cy="16.8457"
            r="15.5879"
            stroke="#203848"
            stroke-width="2"
          />
        </svg>
        <div class="tw-max-h-96 tw-overflow-y-scroll">
          <p class="tw-text-left tw-font-semibold tw-text-22">Organizers</p>
          <p class="tw-text-left tw-text-light-grey tw-text-sm">
            You’ll be able to manage the trip invite, survey, as well as
            finalize/edit all trip details including destination, dates,
            accommodations, and itinerary events.
          </p>
          <p class="tw-text-left tw-font-semibold tw-text-22">Guests</p>
          <p class="tw-text-left tw-text-light-grey tw-text-sm">
            You may invite others, post suggestions for destination, places to
            stay, and things to do, as well as add to the shared group
            itinerary.
          </p>
          <p>
            For organizers, to grant a guest Organizer permissions go to Crew >
            Select Guest > Manage Roles
          </p>
        </div>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import ListItem from "@/components/crew/CrewListItem.vue";
import { DateTime } from "luxon";
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
const ACCEPTED = "accepted";
const MAYBE = "maybe";
const PENDING = "pending";
const DECLINED = "declined";

export default {
  name: "CrewList",
  mixins: [FormattedDateRange],
  components: {
    ListItem
  },
  data() {
    return {
      showExplanation: false,
      copyBtnLabel: "Copy Invite Link",
      activeUser: null,
      tripRSVPDeadline: null,
      dataLoaded: false,
      padding: {
        paddingLeft: "40px",
        paddingRight: "40px",
        paddingTop: "0",
        paddingBottom: "0"
      }
    };
  },
  watch: {
    tripRSVPDeadline(val) {
      this.updateRSVPDeadline(val);
    }
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    going() {
      if (this.trip) {
        return this.trip.users.filter(
          (user) => user.trip_invite.status === ACCEPTED
        );
      } else {
        return [];
      }
    },
    maybe() {
      if (this.trip) {
        return this.trip.users.filter(
          (user) =>
            user.trip_invite.status === MAYBE ||
            user.trip_invite.status === PENDING
        );
      } else {
        return [];
      }
    },
    declined() {
      if (this.trip) {
        return this.trip.users.filter(
          (user) => user.trip_invite.status === DECLINED
        );
      } else {
        return [];
      }
    },
    isOwner() {
      if (!this.trip) return false;
      return this.trip.ownerId === this.user.id;
    },
    isOrganizer() {
      if (!this.trip || !this.user.trips || this.user.trips.length === 0)
        return false;
      let currentTrip = this.user.trips.find((t) => t.id === this.trip.id);
      if (!currentTrip) return false;
      return currentTrip.trip_invite.roles.includes("owner");
    }
  },
  methods: {
    async updateRSVPDeadline(val) {
      if (!this.trip.trip_invitation) return;
      await this.$store.dispatch("trip-invitation/patch", [
        this.trip.trip_invitation.uuid,
        {
          uuid: this.trip.trip_invitation.uuid,
          rsvpDeadline: DateTime.fromISO(val).endOf("day").toISODate()
        }
      ]);
    },
    copyLink() {
      const msg =
        "Hi! I'm planning a group trip on Let's Jetty. Hope you can make it. ✈️ \n\n";
      if (navigator.clipboard) {
        navigator.clipboard.writeText(
          msg +
            window.location.host +
            "/trip-invitation/" +
            this.trip.trip_invitation.uuid
        );
      }

      this.copyBtnLabel = "Copied!";
      setTimeout(() => {
        this.copyBtnLabel = "Copy Invite Link";
      }, 2000);
    },
    setActiveUser(member) {
      if (member.id === this.trip.ownerId) {
        return;
      }
      if (!this.isOrganizer && this.user.id !== member.id) {
        return;
      }

      this.$router.push({
        name: "CrewEditRoute",
        params: {
          userId: member.id
        }
      });
    }
  },
  async beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "The Crew",
      bgColor: "#00ACF5",
      fontColor: "#ffffff",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      tripHubButton: true,
      tripHubButtonId: this.$route.params.id
    });
    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: false
    });

    const res = await this.$store.dispatch("trip/get", [
      this.$route.params.id,
      {
        query: {
          include: "destination,users,survey,trip_invitation"
        }
      }
    ]);

    if (res.trip_invitation && res.trip_invitation.rsvpDeadline)
      this.tripRSVPDeadline = DateTime.fromISO(
        res.trip_invitation.rsvpDeadline,
        {
          setZone: true
        }
      )
        .endOf("day")
        .toISODate();

    this.dataLoaded = true;
  }
};
</script>
