<template>
	<div class="tw-text-left tw-mt-7 tw-cursor-pointer">
		<span
			class="tw-text-left tw-font-figtree tw-text-dark-green tw-font-semibold"
			>{{ list ? list.length : "" }} {{ type }}</span
		>
		<div class="tw-mt-2" v-if="list.length > 0">
			<div
				class="tw-shadow-custom tw-p-4 tw-flex tw-items-center tw-relative tw-mb-2"
				v-for="user in list"
				:key="user.id"
				@click="$emit('edit', user)"
			>
				<UserAvatar :user="user" size="56" />
				<div class="tw-ml-2">
					<span
						class="tw-text-left tw-font-figtree tw-text-dark-green tw-font-semibold tw-mb-1"
						>{{ `${user.firstName} ${user.lastName}` }}</span
					>
					<div
						class="tw-flex"
						v-if="trip.ownerId === user.id || showOrganizer(user)"
					>
						<img src="@/assets/svgs/crew/diamond.svg" alt="Organizer" />
						<span class="tw-text-xxs tw-ml-1 tw-text-light-grey"
							>ORGANIZER</span
						>
					</div>
				</div>
				<img
					src="@/assets/svgs/crew/arrow-forward.svg"
					alt="edit"
					class="tw-absolute tw-right-6 tw-cursor-pointer"
					v-if="showEditIndicator(user)"
				/>
			</div>
		</div>
	</div>
</template>
<script>
import UserAvatar from "@/components/shared/UserAvatar.vue";
export default {
	name: "CrewListItem",
	props: ["type", "list"],
	components: {
		UserAvatar
	},
	computed: {
		user() {
			return this.$store.state.auth.user;
		},
		trip() {
			return this.$store.state.trip.keyedById[this.$route.params.id];
		},
		isOwner() {
			if (!this.trip) return false;
			return this.trip.ownerId === this.user.id;
		},
		isOrganizer() {
			if (!this.trip || !this.user.trips || this.user.trips.length === 0)
				return false;
			let currentTrip = this.user.trips.find((t) => t.id === this.trip.id);
			if (!currentTrip) return false;
			return currentTrip.trip_invite.roles.includes("owner");
		}
	},
	methods: {
		showOrganizer(user) {
			if (!user.trip_invite) return;
			return user.trip_invite.roles.includes("owner");
		},
		showEditIndicator(member) {
			if (this.trip.ownerId === member.id) return false;
			return this.isOwner || this.isOrganizer || this.user.id === member.id;
		}
	},
	async mounted() {
		if (!this.trip) {
			// Dispatch the action to get trip details
			await this.$store.dispatch("trip/get", [
				this.$route.params.id,
				{
					query: {
						include: "destination,users,survey,trip_invitation"
					}
				}
			]);
		}
	}
};
</script>
