<template>
  <div class="crew-list">
    <v-row v-if="user">
      <v-col cols="12" class="tw-px-12">
        <UserAvatar :user="user" />
        <p
          class="tw-text-center tw-font-figtree tw-text-lg tw-text-dark-green tw-mt-7 tw-mb-14"
        >
          {{ `${user.firstName} ${user.lastName}` }}
        </p>
        <h2 class="tw-text-left tw-text-18 tw-font-bold">Edit RSVP</h2>
        <p
          class="info-blurb tw-text-left tw-text-light-grey tw-py-2"
          v-if="(isOwner || isOrganizer) && !loggedUser"
        >
          Received a verbal response? Update their RSVP and we’ll notify them of
          the change.
        </p>
        <p class="info-blurb tw-text-left tw-text-light-grey tw-py-2" v-else>
          Change of plans? Update your RSVP<br />
          below and we’ll notify your crew.
        </p>
        <v-select
          v-model="response"
          :items="responseItems"
          item-color="secondary"
          color="secondary"
          class="j-select-center"
          solo
          prepend-inner-icon="mdi-heart-box-outline"
          append-icon="mdi-chevron-down"
        />
        <template v-if="(isOwner || isOrganizer) && response !== 'declined'">
          <h2 class="tw-text-left tw-text-18 tw-font-bold tw-mt-4">
            Manage Roles
          </h2>
          <p class="info-blurb tw-text-left tw-text-light-grey tw-py-2">
            Is someone helping you coordinate the trip? Assign as organizer to
            give them permission to create surveys and finalize trip details.
          </p>
          <v-select
            v-model="userType"
            :items="userTypes"
            item-color="secondary"
            color="secondary"
            class="j-select-center"
            solo
            prepend-inner-icon="mdi-account"
            append-icon="mdi-chevron-down"
            menu-props="menuProps"
            ref="select"
          >
            <template v-slot:item="{ item }">
              <v-list-item @click="selectItem(item)">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </template>
        <v-btn
          block
          rounded
          x-large
          large
          primary
          depressed
          class="j-btn tw-bg-chartreuse tw-tracking-normal tw-mt-4"
          @click="$router.push({ name: 'CrewListRoute' })"
        >
          Done
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UserAvatar from "@/components/shared/UserAvatar.vue";

export default {
  name: "CrewEdit",
  props: ["member"],
  data() {
    return {
      userType: "guest",
      responseItems: ["accepted", "maybe", "declined"],
      userTypes: [
        { text: "guest", icon: "mdi-account" },
        { text: "organizer", icon: "mdi-diamond" }
      ],
      menuProps: {
        offsetY: true,
        maxHeight: "200px"
      },
      user: null,
      response: null
    };
  },
  components: {
    UserAvatar
  },
  computed: {
    isOwner() {
      return this.trip.ownerId === this.$store.state.auth.user.id;
    },
    isOrganizer() {
      if (
        !this.trip ||
        !this.$store.state.auth.user.trips ||
        this.$store.state.auth.user.trips.length === 0
      )
        return false;
      let currentTrip = this.$store.state.auth.user.trips.find(
        (t) => t.id === this.trip.id
      );
      if (!currentTrip) return false;
      return currentTrip.trip_invite.roles.includes("owner");
    },
    loggedUser() {
      return this.user.id === this.$store.state.auth.user.id;
    },
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    }
  },
  watch: {
    userType: function (val) {
      if (val === "guest") this.demoteUser();
      if (val === "organizer") this.promoteUser();
    },
    response() {
      this.submitRsvp();
    }
  },
  methods: {
    selectItem(item) {
      this.userType = item.text;
      this.$refs.select.blur();
    },
    async promoteUser() {
      try {
        await this.$store.dispatch("trip-promote-to-organizer/create", {
          tripId: this.trip.id,
          tripInviteId: this.user.trip_invite.id
        });
      } catch (error) {
        console.error("Error submitting RSVP:", error);
      }
    },
    async demoteUser() {
      try {
        await this.$store.dispatch("trip-demote-to-user/create", {
          tripId: this.trip.id,
          tripInviteId: this.user.trip_invite.id
        });
      } catch (error) {
        console.error("Error submitting RSVP:", error);
      }
    },
    async submitRsvp() {
      try {
        await this.$store.dispatch("trip-invite/patch", [
          this.user.trip_invite.id,
          { status: this.response }
        ]);
      } catch (error) {
        console.error("Error submitting RSVP:", error);
      }
    }
  },
  async beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "The Crew",
      bgColor: "#00ACF5",
      fontColor: "#fff",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: true
    });

    // Dispatch the action to get trip details
    await this.$store.dispatch("trip/get", [
      this.$route.params.id,
      {
        query: {
          include: "destination,users,survey,trip_invitation"
        }
      }
    ]);

    this.user = this.trip.users.find(
      (user) => user.id == this.$route.params.userId
    );
    this.userType = this.user.trip_invite?.roles.includes("owner")
      ? "organizer"
      : "guest";
    this.response =
      this.user.trip_invite.status === "pending"
        ? "maybe"
        : this.user.trip_invite.status;
    // if (this.response === "declined") {
    //   this.$store.commit("meta/setHeader", {
    //     ...this.$store.state.meta.header,
    //     tripHubButton: false,
    //     showBackButton: true
    //   });
    // }
  },
  destroyed() {
    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: false
    });
  }
};
</script>
<style lang="scss" scoped>
.v-list {
  text-transform: capitalize;
}

.v-list-item__content {
  text-align: left;
}
</style>
